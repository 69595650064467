import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import  axios from "axios";

const MarketData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        "https://yallacap-sever-974c52679fc4.herokuapp.com/global"
      );

      // console.log(json.data)
      setData(response.data);
    };
    fetchData();
  }, []);

  const intl = useIntl();

  return (
    // <div>
      <ul className="justify-left  ml-10 mr-10">
        {/* home */}
        <li className="js-nav-dropdown group relative">
          <span className="ml-2 mr-2">
            <span className="rounded py-1 text-tiny leading-none text-black dark:text-white">
              {intl.formatMessage({ id: "header.coins" })}:
            </span>
            {" "}
            <span className="rounded py-1  text-tiny  uppercase leading-none text-white" style={{ color: 'rgb(135, 139, 145)' }}>
              {data && data ? commafy(Number(data.active_cryptocurrencies)) : "-"}
            </span>
          </span>
          <span className="ml-2 mr-2">
          <span className="rounded py-1 px-1 text-tiny font-bold  leading-none text-black dark:text-white">
            {intl.formatMessage({ id: "header.marketcap" })}:
          </span>
          <span className="rounded  py-1 text-tiny  uppercase leading-none text-white" style={{ color: 'rgb(135, 139, 145)' }}>
            {"$"}{data && data.total_market_cap ? commafy(data.total_market_cap["usd"].toFixed(0)) : "-"}
          </span>
          </span>
          <span className="ml-2 mr-2">
          <span className="rounded py-2 px-1 text-tiny font-bold  leading-none text-black dark:text-white">
            {intl.formatMessage({ id: "header.vol24h" })}:
          </span>
          <span className="rounded  py-1 mr-1 text-tiny  uppercase leading-none text-white" style={{ color: 'rgb(135, 139, 145)' }}>
            {"$"}{data && data.total_volume ? commafy(data.total_volume["usd"].toFixed(0)) : "-"}
          </span>
          {" "}
          {
            data.market_cap_change_percentage_24h_usd > 0 ?
              <span className="rounded py-2    text-tiny font-bold  leading-none text-green arrow-up">
                {data && data.market_cap_percentage ? commafy(data.market_cap_change_percentage_24h_usd.toFixed(2)) : "-"}{"%"}
              </span>
              :
              <span className="rounded py-2 text-tiny font-bold  leading-none text-red arrow-down">
                {data && data.market_cap_percentage ? commafy(data.market_cap_change_percentage_24h_usd.toFixed(2)) : "-"}{"%"}
              </span>
          }
          </span>
          
          <span className="rounded py-2 ml-2 mr-2 text-tiny font-bold  leading-none text-black dark:text-white">
            {intl.formatMessage({ id: "header.dominance" })}:
          </span>
        
          <span className="rounded  py-1 text-xs  mr-1 leading-none text-black dark:text-white" style={{ color: 'rgb(135, 139, 145)' }}>
            BTC
          </span>
          <span className="rounded  py-1 text-tiny mr-3 leading-none text-black dark:text-white" style={{ color: 'rgb(135, 139, 145)' }}>
            {data && data.market_cap_percentage ? commafy(data.market_cap_percentage["btc"].toFixed(2)) : "-"}{"%"}
          </span>
    
          <span className="rounded  py-1 text-xs mr-1 leading-none text-black dark:text-white" style={{ color: 'rgb(135, 139, 145)' }}>
            ETH
          </span>
          <span className="rounded  py-1 text-tiny   leading-none text-black dark:text-white" style={{ color: 'rgb(135, 139, 145)' }}>
             {" "}{data && data.market_cap_percentage ? commafy(data.market_cap_percentage["eth"].toFixed(2)) : "-"}{"%"}
          </span>
        </li>
        {/* <li className="js-nav-dropdown group relative">

                </li>
                <li className="js-nav-dropdown group relative">
 
                </li> */}
      </ul>

  );
};

export default MarketData;
function commafy(num) {
  var str = num.toString().split('.');
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}