import { display } from "@mui/system";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastModalHide } from "../../redux/counterSlice";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";

const Toast_modal = () => {
  const walletModal = useSelector((state) => state.counter.toastModal);
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: "page.modal.top" });

  return (
    <div>
      {/* <!-- Wallet Modal --> */}
      <div
        className={walletModal ? "block modal fade show " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg">
          <div className="modal-content">
            <div className="modal-header"
            				style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 10
                    }}>
             
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(toastModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6 text-center">
            <div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
          marginBottom: 10,
				}}
			>
				<img src="/favicon-tiny.png"   alt="Loading" style={{height: 70}} />
			</div>
      <h5 className="modal-title text-center" id="walletModalLabel"
              >
                     {intl.formatMessage({ id: "page.modal.commingsoon.text" })}
              </h5>
             
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <a
                 style={{cursor: 'pointer'}}
                  onClick={() => dispatch(toastModalHide())}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                >
               {intl.formatMessage({ id: "page.modal.ok" })}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toast_modal;
