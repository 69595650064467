const footerMenuList = [
  {
    id: 1,
    title: "Support",
    key:"support",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/about",
        text: "Request Form",
        key:"reqform"
      },
      {
        id: 2,
        href: "/advertising",
        text: "Advertising",
        key: "advertising"
      },
      // {
      //   id: 3,
      //   href: "/about",
      //   text: "Help Center",
      //   key: "helpcenter"
      // },
      // {
      //   id: 4,
      //   href: "/about",
      //   text: "FAQ",
      //   key: "faq"
      // },
      // {
      //   id: 5,
      //   href: "#",
      //   text: "collections",
      // },
      // {
      //   id: 6,
      //   href: "#",
      //   text: "virtual world",
      // },
    ],
  },
  {
    id: 2,
    title: "About YallaCap",
    key:"aboutheader",
    diffClass: "",
    list: [
      // {
      //   id: 1,
      //   href: "#",
      //   text: "Explore",
      // },
      {
        id: 2,
        href: "/about",
        text: "About Us",
        key: 'about'
      },
      // {
      //   id: 3,
      //   href: "/about",
      //   text: "Careers",
      //   key: 'careers'
      // },
      // {
      //   id: 4,
      //   href: "/about",
      //   text: "Branding Guide",
      //   key: 'branding'
      // },
      // {
      //   id: 5,
      //   href: "/about",
      //   text: "Disclaimer",
      //   key: 'disclaimer'
      // },
      {
        id: 6,
        href: "/terms",
        text: "Terms Of Service",
        key: 'terms'
      },
      // {
      //   id: 7,
      //   href: "/about",
      //   text: "Privacy Policy",
      //   key: 'privacy'
      // },
      // {
      //   id: 8,
      //   href: "/about",
      //   text: "Ad Policy",
      //   key: "adv"
      // },
    ],
  },
  {
    id: 3,
    title: "Community",
    key: "community",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "https://twitter.com/yallacap",
        text: "Twitter",
        key: 'twitter'
      },
      {
        id: 2,
        href: "https://t.me/yallacap",
        text: "Telegram",
        key: 'telegram'
      },
      {
        id: 3,
        href: "https://www.youtube.com/@yallacap",
        text: "Youtube",
        key: 'youtube'
      },
      {
        id: 4,
        href: "mailto:enquires@yallacap.com",
        text: "Email",
        key: 'email'
      },
    ],
  },
];

const socialIcons = [
  // {
  //   id: 1,
  //   href: "https://twitter.com/yallacap",
  //   text: "twitter",
  // },
  // {
  //   id: 2,
  //   href: "https://t.me/yallacap",
  //   text: "telegram",
  // },

  // {
  //   id: 3,
  //   href: "https://www.youtube.com/@yallacap",
  //   text: "youtube",
  // },
  // {
  //   id: 4,
  //   href: "enquires@yallacap.com",
  //   text: "email",
  // },
  // {
  //   id: 5,
  //   href: "https://www.tiktok.com",
  //   text: "tiktok",
  // },
];

export { footerMenuList, socialIcons };
